<template>
	<div>
		<img class="img-bg" src="../assets/contact/top_bg.jpg" alt="">
		<!-- 授权IP -->
		<div class="content-cls authorized-box">
			<div class="main-title">
				<div class="big-title">授权IP</div>
				<div class="small-title">— Authorized IP —</div>
			</div>
			<!-- 列表 -->
			<div class="list-box">
				<div class="li-cls" v-for="item in authorizedList" :key="item.id" @click="jump(`/ipProduct/${item.id}`)">
					<div class="flex-box">
						<img class="li-logo" :src="item.img_id" alt="">
						<div class="li-detail">
							<div class="li-title">{{item.name}}</div>
							<div class="li-bg-box">
								<div class="li-msg">{{item.desc}}</div>
							</div>
						</div>
					</div>
					<div class="li-bottom">
						<div class="li-img-list">
							<img class="img-cls" @click.stop="jump(`/productDetail/${img.id}`)" v-for="(img, index) in item.productlist" :key="index" :src="img.img_id" alt="">
						</div>
						<div class="more-btn">更多作品>></div>
					</div>
				</div>
			</div>
		</div>

		<!-- 原创IP -->
		<div class="content-cls original-box">
			<div class="main-title">
				<div class="big-title">原创IP</div>
				<div class="small-title">— Original IP —</div>
			</div>
			<!-- 列表 -->
			<div class="list-box">
				<div class="li-cls" v-for="item in originalList" :key="item.id" @click="goList(item.id)">
					<div class="flex-box">
						<img class="li-logo" :src="item.img_id" alt="">
						<div class="li-detail">
							<div class="li-title">{{item.name}}</div>
							<div class="li-bg-box">
								<div class="li-msg">{{item.desc}}</div>
							</div>
						</div>
					</div>
					<div class="li-bottom">
						<div class="li-img-list">
							<img class="img-cls" @click.stop="jump(`/productDetail/${img.id}`)" v-for="(img, index) in item.productlist" :key="index" :src="img.img_id" alt="">
						</div>
						<div class="more-btn">更多作品>></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				banner: [], // 顶部轮播图
				authorizedList: [], // 授权IP列表
				originalList: [], // 原创IP列表
			}
		},
		created() {
			this.getData();
		},
		methods: {
			// 获取ip列表
			getData() {
				this.$api.getIpZone().then(res => {
					if (res.code == '0000') {
						this.banner = res.data.bannerlist;
						this.authorizedList = res.data.c_iplist;
						this.originalList = res.data.o_iplist;
					}
				})
			},
			// 跳转到IP产品列表
			goList(id) {
				this.$router.push({
					path: `/ipProduct/${id}`
				})
			},
			// 跳转
			jump(url) {
				this.$router.push({
					path: url
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.list-box {
		margin-top: 3rem;

		.li-cls {
			padding: 0 1rem;
			margin-bottom: 2rem;
		}
		
		.flex-box{
			display: flex;
			align-items: flex-start;
		}

		.li-logo {
			height: 8rem;
			width: 8rem;
			overflow: hidden;
			border-radius: 1rem;
			transition: all .3s;
		}
		
		.li-detail {
			flex: 1;
			text-align: left;
			margin-left: 1rem;

			.li-title {
				font-size: 1.4rem;
				margin-bottom: 0.6rem;
			}

			.li-msg {
				font-size: 0.8rem;
				line-height: 1.2rem;
				color: #666;
				padding: 0.4rem;
				border-radius: 0.6rem;
			}
		}

		.li-bg-box {
			background: #fafafa;
		}
		
		.li-bottom{
			.li-img-list {
				margin-top: 1.2rem;
				white-space: nowrap;
			}
			
			.img-cls {
				width: 6rem;
				height: 6rem;
				display: inline-block;
				margin-left: 1rem;
				transition: all 0.3s;
				border-radius: 0.6rem;
			}
			
			.img-cls:first-child {
				margin-left: 0;
			}
		}

		.more-btn {
			margin-top: 1rem;
			text-align: right;
			color: #666;
			cursor: pointer;
		}
		
	}

	.authorized-box {
		padding-top: 3rem;
	}
</style>
