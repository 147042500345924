<template>
	<div>
		<img class="img-bg" v-if="bannerInfo" :src="bannerInfo[0]" alt="">
		<!-- 授权IP -->
		<div class="content-cls authorized-box">
			<div class="main-title">
				<div class="big-title">授权IP</div>
				<div class="small-title">— Authorized IP —</div>
			</div>
			<!-- 列表 -->
			<div class="list-box">
				<div class="li-cls" v-for="item in authorizedList" :key="item.id" @click="jump(`/ipProduct/${item.id}`)">
					<img class="li-logo" :src="item.img_id" alt="">
					<div class="li-detail">
						<div class="li-title">{{item.name}}</div>
						<div class="li-bg-box">
							<div class="li-msg">{{item.desc}}</div>
							<div class="li-img-list">
								<img class="img-cls" @click.stop="jump(`/productDetail/${img.id}`)" v-for="(img, index) in item.productlist" :key="index" :src="img.img_id" alt="">
							</div>
							<div class="more-btn">更多作品>></div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 原创IP -->
		<div class="content-cls original-box">
			<div class="main-title">
				<div class="big-title">原创IP</div>
				<div class="small-title">— Original IP —</div>
			</div>
			<!-- 列表 -->
			<div class="list-box">
				<div class="li-cls" v-for="item in originalList" :key="item.id" @click="goList(item.id)">
					<img class="li-logo" :src="item.img_id" alt="">
					<div class="li-detail">
						<div class="li-title">{{item.name}}</div>
						<div class="li-bg-box">
							<div class="li-msg">{{item.desc}}</div>
							<div class="li-img-list">
								<img class="img-cls" @click.stop="jump(`/productDetail/${img.id}`)" v-for="(img, index) in item.productlist" :key="index" :src="img.img_id" alt="">
							</div>
							<div class="more-btn">更多作品>></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	export default {
		data() {
			return {
				banner: [], // 顶部轮播图
				authorizedList: [], // 授权IP列表
				originalList: [], // 原创IP列表
			}
		},
		computed: {
			...mapState({
				bannerInfo: state => state.bannerInfo[3], // banner图
			})
		},
		created() {
			this.getData();
		},
		methods: {
			// 获取ip列表
			getData() {
				this.$api.getIpZone().then(res => {
					if (res.code == '0000') {
						this.banner = res.data.bannerlist;
						this.authorizedList = res.data.c_iplist;
						this.originalList = res.data.o_iplist;
					}
				})
			},
			// 跳转到IP产品列表
			goList(id) {
				this.$router.push({
					path: `/ipProduct/${id}`
				})
			},
			// 跳转
			jump(url) {
				this.$router.push({
					path: url
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.list-box {
		margin-top: 90px;

		.li-cls {
			position: relative;
			margin-bottom: 70px;
			cursor: pointer;
		}

		.li-logo {
			height: 410px;
			width: 410px;
			overflow: hidden;
			border-radius: 80px;
			position: absolute;
			left: 0;
			top: 0;
			transition: all .3s;
		}
		
		.li-logo:hover {
			transform: scale(1.05);
		}

		.li-detail {
			text-align: left;
			margin-left: 220px;
			padding-top: 20px;

			.li-title {
				font-size: 26px;
				padding-left: 270px;
				margin-bottom: 34px;
			}

			.li-msg {
				font-size: 14px;
				color: #666;
			}
		}

		.li-bg-box {
			background: #fafafa;
			border-radius: 52px;
			padding: 25px 25px 25px 270px;
			min-height: 340px;

			.li-img-list {
				margin-top: 33px;
				white-space: nowrap;
			}

			.img-cls {
				width: 230px;
				height: 230px;
				display: inline-block;
				margin-left: 27px;
				transition: all 0.3s;
			}

			.img-cls:first-child {
				margin-left: 0;
			}
			
			.img-cls:hover {
				transform: scale(1.05);
			}
		}

		.more-btn {
			margin-top: 20px;
			text-align: right;
			color: #666;
			cursor: pointer;
		}
		
		.more-btn:hover{
			color: #329ADD !important;
		}
	}

	.authorized-box {
		padding-top: 90px;
	}
</style>
